import "@fontsource/rokkitt/200.css"
import "@fontsource/rokkitt/400.css"
import "@fontsource/rokkitt/600.css"
import "@fontsource/rokkitt/700.css"
import "@fontsource/rokkitt/800.css"
import "@fontsource/rokkitt/900.css"


import 'bootstrap/dist/css/bootstrap.min.css';

const React = require("react")
// const Layout = require("./src/components/layout").default


// export const wrapPageElement = ({element, props }) => {
//     return <Layout {...props}>{element}</Layout>
// }